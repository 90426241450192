import React from "react";
import { MUI } from "@amps/material-ui";

import useStyles from "components/RequestPage/styles/PlanNotes.style";

import { ExpandMore } from "@material-ui/icons";
import { Paper, TableCell, Typography } from "@material-ui/core";
import { PlanNoteModel } from "models/planNote.model";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import MemberModel from "models/member.model";
import { checkIsPreCallAlert } from "utils/memberUtils";

interface PlanNotesProps {
  planNotes: PlanNoteModel[] | null;
  isLoading: boolean;
  member?: MemberModel;
  showPreCallAlert?: boolean;
}

const planNoteTypeMapping: { [key: string]: string } = {
  "1": "Add-Pay/Authority Request",
  "2": "Care Navigation Incentives",
  "3": "Claim Pricing/Exception",
  "4": "Client Detail",
  "5": "Contact/Info",
  "6": "Dispute Threshold",
  "7": "Network Information",
  "8": "Other",
  "9": "Termination/Run-out",
  "10": "TPA Name History",
  "11": "TPA/Client Note",
  "12": 'Pre-Call Alert',
  "-1": "Original"
};

export default function PlanNotes(props: PlanNotesProps) {
  const { planNotes, isLoading, member, showPreCallAlert = false } = props;
  const classes = useStyles();

  let isPreCallAlert = planNotes?.some((pn) => pn.planNoteTypeId === '12')
  return (
    <MUI.Accordion className={classes.expPanel}>
      <MUI.AccordionSummary expandIcon={<ExpandMore />}>
        <MUI.Grid container spacing={0} alignItems="center" className={classes.panelTitle}>
          <MUI.Grid item xs>
            <span>
              <b>Plan notes</b>
            </span>
          </MUI.Grid>
          {isPreCallAlert ? (
            <MUI.Grid item xs="auto">
              <MUI.Typography variant="body1" style={{ color: "#a921ed", textAlign: 'right' }}>
                Pre-Call Alert
              </MUI.Typography>
            </MUI.Grid>
          ) : undefined}
        </MUI.Grid>
      </MUI.AccordionSummary>
      <MUI.Divider variant="middle" />
      <MUI.AccordionDetails>
        {isLoading ? (
          <MUI.Grid item xs={12}>
            <MUI.LinearProgress />
          </MUI.Grid>
        ) : (
          <MUI.Grid container spacing={2}>
            {planNotes && planNotes.length > 0 ? (
              <MUI.TableContainer component={Paper}>
                <MUI.Table>
                  <MUI.TableHead>
                    <MUI.TableRow>
                      {["Type", "Attached File", "Note", "Date Created", "User"].map(header => (
                        <MUI.TableCell key={header} style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                          {header}
                        </MUI.TableCell>
                      ))}
                    </MUI.TableRow>
                  </MUI.TableHead>
                  <MUI.TableBody>
                    {planNotes.map(note => (
                      <MUI.TableRow key={note.id}>
                        <TableCell style={{ color: note.planNoteTypeId === "12" ? '#a921ed' : 'black' }}>
                          {planNoteTypeMapping[note.planNoteTypeId] || "N/A"}
                        </TableCell>
                        <TableCell>{note.attachedFilename ? <FileIcon /> : "None"}</TableCell>
                        <TableCell style={{ color: note.planNoteTypeId === "12" ? '#a921ed' : 'black' }}>
                          {note.note || "N/A"}
                        </TableCell>
                        <TableCell style={{ color: note.planNoteTypeId === "12" ? '#a921ed' : 'black' }}>
                          {new Date(note.createTs).toLocaleDateString()}
                        </TableCell>
                        <TableCell style={{ color: note.planNoteTypeId === "12" ? '#a921ed' : 'black' }}>
                          {note.createdUser.code || "N/A"}
                        </TableCell>
                      </MUI.TableRow>
                    ))}
                  </MUI.TableBody>
                </MUI.Table>
              </MUI.TableContainer>
            ) : (
              <Typography className={classes.noOptions}>No Plan Notes</Typography>
            )}
          </MUI.Grid>
        )}
      </MUI.AccordionDetails>
    </MUI.Accordion>
  );
}
