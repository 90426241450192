import React, { useState } from "react";
import { MUI } from "@amps/material-ui";
import clsx from "clsx";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";
import msk from "msk";
import { formatWithPattern } from "utils/numFormatUtils";
import { getMemberEligibility, checkIsPreCallAlert, getPreCallAlertNote } from "utils/memberUtils";
import FormikTextField from "containers/FormikTextField";
import NumberFormatField from "containers/NumberFormatField";
import useStyles from "components/shared/styles/MemberProfile.style";
import GroupModel from "models/group.model";
import MemberModel from "models/member.model";
import ClientModel from "models/client.model";
import ProductModel from "models/product.model";
import MemberNoteModel from "models/memberNote.model";
import { useStoreActions } from "redux/reducers/hooks";
import { Edit, Check, Close, StarRate } from "@material-ui/icons";
import { sortProducts } from "./styles/ProductSortingUtils";
import { Tooltip } from "@material-ui/core";

type Props = {
  singleColumn?: boolean;
  member: MemberModel;
  group: GroupModel;
  client: ClientModel;
  products: ProductModel[];
  showPreCallAlert?: boolean
};
interface EditPhoneFormValues {
  phone?: string;
}
interface EditEmailFormValues {
  email: string;
}

const defaultPhoneFormat = "(###) ###-####";
const extraPhoneFormat = "#-(###) ###-####";

const getValidPhoneLength = (phone: string) => {
  if (!phone) return true;

  const validLength = phone[0] === "1" ? 11 : 10;

  return phone.length === validLength;
};

const EditPhoneFormSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Required")
    .test("isValidPhone", "Phone value must be valid", getValidPhoneLength)
});

const EditEmailFormSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email("Email value must be valid")
});

export function MemberProfile(props: Props) {
  const [isPhoneEditing, setIsPhoneEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [isEmailSubmitting, setIsEmailSubmitting] = useState(false);
  const [isPhoneSubmitting, setIsPhoneSubmitting] = useState(false);
  const classes = useStyles();
  const { singleColumn = false, member, group, client, products, showPreCallAlert = false } = props;
  const updateMember = useStoreActions(actions => actions.member.updateMember);

  const showPhoneEdit = () => setIsPhoneEditing(true);
  const resetPhoneEdit = (phoneForm: FormikProps<EditPhoneFormValues>) => {
    phoneForm.resetForm();

    setIsPhoneEditing(false);
  };

  const handleEditPhone = async (values: EditPhoneFormValues) => {
    setIsPhoneSubmitting(true);

    try {
      const data = { phone: values.phone };

      await updateMember({ memberId: member.id, data });

      member.phone = data.phone;
      setIsPhoneEditing(false);
    } finally {
      setIsPhoneSubmitting(false);
    }
  };

  const showEmailEdit = () => setIsEmailEditing(true);
  const resetEmailEdit = (emailForm: FormikProps<EditEmailFormValues>) => {
    emailForm.resetForm();
    setIsEmailEditing(false);
  };

  const handleEditEmail = async (values: EditEmailFormValues) => {
    setIsEmailSubmitting(true);

    try {
      await updateMember({ memberId: member.id, data: values });

      member.email = values.email;
      setIsEmailEditing(false);
    } finally {
      setIsEmailSubmitting(false);
    }
  };

  const getPhoneFormat = (value: string) => {
    const format = value[0] === "1" ? extraPhoneFormat : defaultPhoneFormat;

    return formatWithPattern(value, format);
  };

  const renderProducts = () => {
    if (!products.length)
      return (
        <MUI.Typography variant="body1" color="textSecondary" className={classes.textItem}>
          No products
        </MUI.Typography>
      );
    const sortedProducts = sortProducts(products);
    return (
      <MUI.Grid container spacing={0}>
        {sortedProducts.map(product => (
          <MUI.Grid
            key={product.id}
            item
            xs={singleColumn ? 12 : 3}
            className={clsx("product-item", classes.productItem, classes.textItem)}
          >
            <MUI.Typography variant="body1" color="textSecondary" className={classes.textItem}>
              &#9679; {product.name}
            </MUI.Typography>
          </MUI.Grid>
        ))}
      </MUI.Grid>
    );
  };

  return (
    <MUI.Grid container spacing={3} className={classes.container}>
      <MUI.Grid item xs={singleColumn ? 12 : 6} className={classes.gridItem}>
        <MUI.Typography variant="body2" color="textSecondary">
          Email
        </MUI.Typography>
        <Formik
          enableReinitialize
          initialValues={{
            email: member.email || ""
          }}
          validationSchema={EditEmailFormSchema}
          onSubmit={handleEditEmail}
        >
          {emailForm => (
            <Form>
              <div className={classes.editRow}>
                <Field
                  name="email"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.textField
                    }
                  }}
                  disabled={!isEmailEditing || isEmailSubmitting}
                />
                {!isEmailEditing && (
                  <MUI.IconButton className={classes.editButton} onClick={showEmailEdit} id="email-edit-button">
                    <Edit />
                  </MUI.IconButton>
                )}
                {isEmailEditing && (
                  <>
                    <div className={classes.progressWrapper}>
                      <MUI.IconButton
                        className={classes.editButton}
                        type="submit"
                        id="email-submit-button"
                        disabled={isEmailSubmitting}
                      >
                        <Check />
                      </MUI.IconButton>
                      {isEmailSubmitting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <MUI.Divider className={classes.divider} />
                    <MUI.IconButton className={classes.editButton} onClick={() => resetEmailEdit(emailForm)}>
                      <Close />
                    </MUI.IconButton>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </MUI.Grid>
      <MUI.Grid item xs={singleColumn ? 12 : 6} className={classes.gridItem}>
        <MUI.Typography variant="body2" color="textSecondary">
          Phone
        </MUI.Typography>
        <Formik
          enableReinitialize
          initialValues={{
            phone: (member.phone && msk.fit(member.phone, "99999999999")) || ""
          }}
          validationSchema={EditPhoneFormSchema}
          onSubmit={handleEditPhone}
        >
          {phoneForm => (
            <Form>
              <div className={classes.editRow}>
                <Field
                  name="phone"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.textField
                    },
                    inputComponent: NumberFormatField
                  }}
                  // eslint-disable-next-line
                  inputProps={{
                    format: getPhoneFormat
                  }}
                  disabled={!isPhoneEditing || isPhoneSubmitting}
                />
                {!isPhoneEditing && (
                  <MUI.IconButton className={classes.editButton} onClick={showPhoneEdit} id="phone-edit-button">
                    <Edit />
                  </MUI.IconButton>
                )}
                {isPhoneEditing && (
                  <>
                    <div className={classes.progressWrapper}>
                      <MUI.IconButton
                        className={classes.editButton}
                        type="submit"
                        id="phone-submit-button"
                        disabled={isPhoneSubmitting}
                      >
                        <Check />
                      </MUI.IconButton>
                      {isPhoneSubmitting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <MUI.Divider className={classes.divider} />
                    <MUI.IconButton className={classes.editButton} onClick={() => resetPhoneEdit(phoneForm)}>
                      <Close />
                    </MUI.IconButton>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </MUI.Grid>
      <MUI.Grid item xs={12} className={classes.gridItem}>
        {checkIsPreCallAlert(member.patient_notes) && showPreCallAlert ? (
         <MUI.Typography align="left" variant="body1" style={{ color: "#a921ed", display: "inline-flex", alignItems: "center" }}>
            <Tooltip title={getPreCallAlertNote(member.patient_notes, member.notes)} placement="bottom">
           <span>Pre-Call Alert</span> 
           </Tooltip>
          </MUI.Typography>
        ) : (
          undefined
        )}
      </MUI.Grid>
      <MUI.Grid item xs={singleColumn ? 12 : 6} className={classes.gridItem}>
        <MUI.Typography variant="body2" color="textSecondary">
          Address
        </MUI.Typography>
        <MUI.Typography variant="body1" color="textSecondary" className={classes.textItem}>
          {member.addressLine1}
        </MUI.Typography>
        {!singleColumn && (
          <MUI.Typography variant="body1" color="textSecondary" className={classes.textItem}>
            {member.city} {member.postalCode}
          </MUI.Typography>
        )}
        <MUI.Typography variant="body2" color="textSecondary">
          <b>{member.state}</b>
        </MUI.Typography>
      </MUI.Grid>
      <MUI.Grid item xs={singleColumn ? 12 : 6} className={classes.gridItem}>
        <MUI.Typography variant="body2" color="textSecondary">
          Eligibility
        </MUI.Typography>
        <MUI.Typography variant="body1" color="textSecondary" className={classes.textItem}>
          {getMemberEligibility(member)}
        </MUI.Typography>
      </MUI.Grid>
      <MUI.Grid item xs={singleColumn ? 12 : 6} className={classes.gridItem}>
        <MUI.Typography variant="body2" color="textSecondary">
          Client
        </MUI.Typography>
        <MUI.Typography variant="body1" color="textSecondary" className={classes.textItem}>
          <span id="client-name">{client.name}</span>
        </MUI.Typography>
      </MUI.Grid>
      <MUI.Grid item xs={singleColumn ? 12 : 6} className={classes.gridItem}>
        <MUI.Typography variant="body2" color="textSecondary">
          Client Group
        </MUI.Typography>
        <MUI.Typography variant="body1" color="textSecondary" className={classes.textItem}>
          <span id="group-name">
            {group.name}
            {group.isVip && (
              <MUI.Icon>
                <StarRate className={classes.starIcon} />
              </MUI.Icon>
            )}
          </span>
        </MUI.Typography>
      </MUI.Grid>

      <MUI.Grid item xs={12} className={classes.productItemHeader}>
        <MUI.Typography variant="body2" color="textSecondary">
          Products accessible by member via client group
        </MUI.Typography>
        {renderProducts()}
      </MUI.Grid>
    </MUI.Grid>
  );
}

export default MemberProfile;
